<template>
  <div class="container">
    <div class="top">
      <h2>周边推</h2>
      <p>周边全网优惠。</p>
    </div>
    <div class="middle">
      <img
        class="bg"
        src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_155639_62a0563764679.png"
      />
    </div>
    <div class="bottom">
      <div class="btn-1" @click="toLogin(1)">
        <img
          src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_161808_62a05b404a755.png"
        />
        <p>商家登录</p>
      </div>
      <!-- <div class="btn-2" @click="toLogin(2)">
        <img
          src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_161830_62a05b56e2491.png"
        />
        <p>代理登录</p>
      </div> -->
    </div>

    <div>
      <van-popup
        v-model:show="show"
        position="bottom"
        closeable
        :style="{ height: '100%' }"
      >
        <div class="top-1">
          <h2>周边推</h2>
          <p>你好！{{ title }}。</p>
        </div>
        <div class="from">
          <div>
            <input
              type="text"
              v-model="queryParams.phone"
              placeholder="请输入手机号"
            />
          </div>
          <div>
            <input
              type="password"
              v-model="queryParams.password"
              placeholder="请输入密码"
            />
          </div>
          <div>
            <button class="submit" @click="onSubmit">提交</button>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Popup } from 'vant'
import { getToken, setToken } from '@/utils/auth'
export default {
  components: {
    [Popup.name]: Popup,
  },

  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  data() {
    return {
      show: false,
      queryParams: {
        phone: '',
        password: '',
      },
      title: '商家',
      redirect: null,
    }
  },
  mounted() {
    const token = getToken()
    if (token && token !== '') {
      this.$router.push({ path: this.redirect || '/index' }).catch(() => {})
    }
  },
  methods: {
    toLogin(type) {
      if (type === 1) {
        this.title = '商家'
      } else {
        this.title = '代理'
      }
      this.show = true
    },
    onSubmit() {
      // const that = this
      if (this.queryParams.username === '') {
        this.$notify({ type: 'danger', message: '请先输入手机号' })
        return
      }
      if (this.queryParams.password === '') {
        this.$notify({ type: 'danger', message: '请先输入密码' })
        return
      }
      this.$http({
        url: '/merchant/login',
        method: 'post',
        data: this.queryParams,
      })
        .then((res) => {
          setToken(res.access_token)
          this.$router.push({ path: this.redirect || '/index' }).catch(() => {})
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  padding: 10vh 0;
  height: 100vh;
  z-index: 99999;
  background: #fff;
  position: fixed;

  .top {
    height: 10vh;
    padding-bottom: 20px;
    padding-left: 10vw;
  }
  .top,
  .top-1 {
    h2 {
      font-size: 25px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    p {
      font-size: 21px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .top-1 {
    height: 10vh;
    padding: 10vh 0;
    width: 80%;
    margin: 0 auto;
  }
  .middle {
    .bg {
      height: 50vh;
      width: 70%;
      display: block;
      object-fit: contain;
      margin: 0 auto;
      text-align: center;
    }
  }
  .bottom {
    height: 10vh;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    width: 80%;
    .btn-1,
    .btn-2 {
      height: 44px;
      border-radius: 8px 8px 8px 8px;
      margin: 12px 0;
      font-size: 16px;
      line-height: 44px;
      img {
        padding-right: 10px;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        padding-bottom: 2px;
      }
      p {
        margin: 0;
        padding: 0;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .btn-1 {
      background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
    }
    .btn-2 {
      background: linear-gradient(235deg, #f59e4d 0%, #f37750 100%);
      height: 44px;
    }
  }

  .from {
    width: 80%;
    margin: 0 auto;
    input {
      width: calc(100% - 20px);
      border: none;
      height: 52px;
      background: #fafafa;
      border-radius: 8px 8px 8px 8px;
      margin: 10px 0;
      color: rgb(76 76 75 / 81%);
      padding-left: 20px;
    }
    .submit {
      margin-top: 40px;
      border: none;
      width: 100%;
      height: 52px;
      background: #30d281;
      color: #fff;
      border-radius: 8px 8px 8px 8px;
    }
  }
}
</style>
